import clsx from "clsx";
import parse from "html-react-parser";
import React from "react";
import MaxWidth from "./MaxWidth";

interface IProps {
	text: string;
	textColor?: string;
	maxWidth?: string;
	fullWidth?: boolean;
}

const BodyText = ({
	text,
	textColor = "currentColor",
	fullWidth = false,
	maxWidth = null,
}: IProps): JSX.Element => {
	return !!text && (
		<MaxWidth maxWidth={maxWidth} className={clsx("BodyText flex-grow")}>
			<div
				className={clsx("BodyText__content prose")}
				style={{ color: textColor }}
			>
				{parse(text)}
			</div>
		</MaxWidth>
	);
};

export default BodyText;
