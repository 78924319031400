import clsx from "clsx";

const PlayButton = ({ onClick, leftAlignPlayButton = false }) => {
  return (
    <button
      className={clsx(
        "PlayButton",
        "rounded-full",
        "w-[120px] h-[120px]",
        "transition-opacity transform-gpu",
        "opacity-70 hover:opacity-100 absolute ",
        {
          "bottom-[35%] left-[5%]": leftAlignPlayButton,
          "top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2": !leftAlignPlayButton,
        }
      )}
      onClick={onClick}
    >
      <svg
        width="120"
        height="120"
        viewBox="0 0 120 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="60" cy="60" r="59" stroke="#F7F7F7" strokeWidth="2" />
        <path
          d="M50.332 43.2567L79.332 59.9998L50.332 76.743L50.332 43.2567Z"
          stroke="#F7F7F7"
          strokeWidth="2"
        />
      </svg>
    </button>
  );
};

export default PlayButton;
