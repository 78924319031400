import clsx from "clsx";
import { Children, useEffect, useState } from "react";
import { A11y, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { ArrowLeft, ArrowRight } from "@components/Icons";
import { useWindowSize } from "@lib/helpers";

const getSlidesOffset = (width) => {
	const offset = Math.round((width - 1792) / 2);

	let gutterWidth = 60;

	if (width < 1024) gutterWidth = 30;

	return offset > 0 ? offset + gutterWidth : gutterWidth;
};

const EntryCarousel = ({
	children,
	columns = 2,
	isFlush = true,
	thumbnailImageHeight = null,
	isFeatured = false,
	entriesPerSlide = 3,
	entryBackgroundColor = null,
	className = "",
}) => {
	const { width } = useWindowSize();

	const [swiper, setSwiper] = useState(null);
	const [isBeginning, setIsBeginning] = useState(true);
	const [isEnd, setIsEnd] = useState(false);
	const [offset, setOffset] = useState(null);

	useEffect(() => {
		setOffset(getSlidesOffset(width));
	}, [width]);

	const handleSlideChange = () => {
		setIsBeginning(swiper.isBeginning);
		setIsEnd(swiper.isEnd);
	};

	const NavButton = ({ direction = "next" }) => {
		const handleClick = () => {
			if (direction === "next") swiper.slideNext();
			if (direction === "prev") swiper.slidePrev();
		};

		return (
			<button
				className={clsx(
					"w-15 h-15",
					"bg-white-01",
					"rounded-full",
					"flex items-center justify-center",
					"absolute bottom-[-50px] z-30",
					"transition-all",
					"hover:translate-x-2",
					{
						"opacity-0 pointer-events-none":
							(direction === "prev" && isBeginning) ||
							(direction === "next" && isEnd),
						"right-0 lg:right-0": direction === "next",
						"left-0 lg:left-0": direction === "prev",
					}
				)}
				onClick={handleClick}
			>
				{direction === "next" ? <ArrowRight /> : <ArrowLeft />}
			</button>
		);
	};

	return (
		<div className={clsx("EntryCarousel")}>
			<div
				className={clsx(
					"EntryCarousel__content max-w-screen-xl mx-auto px-5 md:px-0",
					"relative",
					className
				)}
			>
				<Swiper
					modules={[Pagination, A11y]}
					pagination={{
						clickable: true,
						el: ".carousel-pagination",
					}}
					a11y={{
						prevSlideMessage: "Previous slide",
						nextSlideMessage: "Next slide",
					}}
					slidesPerView={1}
					spaceBetween={isFlush ? 0 : 50}
					slidesOffsetBefore={0}
					slidesOffsetAfter={0}
					onSwiper={setSwiper}
					onSlideChange={handleSlideChange}
					breakpoints={{
						640: {
							slidesPerView: 1,
							spaceBetween: isFlush ? 0 : 30,
						},
						768: {
							slidesPerView: 2,
							spaceBetween: isFlush ? 0 : 50,
						},
						1024: {
							slidesPerView: entriesPerSlide ? entriesPerSlide : 3,
							spaceBetween: isFlush ? 0 : 50,
						},
						1280: {
							slidesPerView: entriesPerSlide ? entriesPerSlide : 3,
						},
						1536: {
							slidesPerView: entriesPerSlide ? entriesPerSlide : 3,
						},
						1792: {
							slidesPerView: entriesPerSlide ? entriesPerSlide : 3,
						},
						2048: {
							slidesPerView: entriesPerSlide ? entriesPerSlide : 3,
						},
					}}
					className={clsx("EntryCarousel__slides")}
				>
					{Children.map(children, (child, index) => {
						return (
							<SwiperSlide
								key={index}
								style={{
									legacyBackgroundColor: entryBackgroundColor,
									height: entryBackgroundColor ? "auto" : null,
								}}
								className={clsx("EntryCarousel__slide")}
							>
								{child}
							</SwiperSlide>
						);
					})}
				</Swiper>
				<NavButton direction={"prev"} />
				<NavButton direction={"next"} />
				<div
					className={clsx(
						"carousel-pagination w-full h-15 leading-[50px] absolute -bottom-15 text-center"
					)}
				></div>
			</div>
		</div>
	);
};

export default EntryCarousel;
