import clsx from "clsx";
import { useState } from "react";

import { ArrowRight } from "@components/Icons";
import Target from "@components/Target";

const SecondaryButton = ({
	target,
	isFullWidth = false,
	isFullWidthMobile = false,
	textColor = "currentColor",
	legacyBackgroundColor = "transparent",
	borderColor = "currentColor",
	hoverBackgroundColor = "#0D1418",
	hoverTextColor = "#f7f7f7",
	noXPadding = false,
	transition = true,
}) => {
	const [isHovered, setIsHovered] = useState(false);
	const [targetBackgroundColor, setTargetBackgroundColor] = useState(
		legacyBackgroundColor
	);
	const [targetTextColor, setTargetTextColor] = useState(textColor);

	const targetBackgroundHoverColor =
		!!hoverBackgroundColor && hoverBackgroundColor !== "transparent"
			? hoverBackgroundColor
			: legacyBackgroundColor;

	const targetTextHoverColor =
		!!hoverTextColor && hoverTextColor !== "transparent"
			? hoverTextColor
			: textColor;

	const handleMouseEnter = () => {
		setIsHovered(true);
		setTargetBackgroundColor(targetBackgroundHoverColor);
		setTargetTextColor(targetTextHoverColor);
	};

	const handleMouseLeave = () => {
		setIsHovered(false);
		setTargetBackgroundColor(legacyBackgroundColor);
		setTargetTextColor(textColor);
	};

	const targetBorderColor =
		legacyBackgroundColor === "transparent" || borderColor !== "currentColor"
			? borderColor
			: legacyBackgroundColor;

	return (
		<Target
			target={target}
			className={clsx(
				"SecondaryButton",
				"inline-flex items-center",
				"py-2",
				"border rounded-sm",
				{
					"w-full": isFullWidth,
					"w-full lg:w-auto": isFullWidthMobile,
					"px-5": !noXPadding,
				},
				{ "transition-colors": transition },
				{ "hover:!border-black-01": borderColor != "transparent" },
				"group"
			)}
			style={{
				color: targetTextColor,
				backgroundColor: targetBackgroundColor,
				borderColor: targetBorderColor,
			}}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<span className={clsx("SecondaryButton__label")}>{target.text}</span>
			<span
				className={clsx(
					"SecondaryButton__icon",
					"pl-4 ml-auto",
					"transition-transform group-hover:translate-x-1"
				)}
			>
				<ArrowRight />
			</span>
		</Target>
	);
};

export default SecondaryButton;
