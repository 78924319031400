import clsx from "clsx";

import SecondaryButton from "@components/SecondaryButton";

const ButtonBlock = ({
  target,
  verticalPadding = "none",
  buttonBackgroundColor,
  buttonTextColor,
  horizontalAlignment = "center",
}) => {
  return (
    <div
      className={clsx("ButtonBlock flex flex-grow", {
        "py-7.5": verticalPadding === "sm",
        "py-7.5 lg:py-15": verticalPadding === "md",
        "py-15 lg:py-30": verticalPadding === "lg",
        "py-30 lg:py-60": verticalPadding === "xl",
        "lg:justify-center": horizontalAlignment === "center",
        "justify-start": horizontalAlignment === "start",
        "lg:justify-end": horizontalAlignment === "end",
      })}
    >
      <div className={clsx("ButtonBlock__secondaryButton")}>
        <SecondaryButton
          target={target}
          legacyBackgroundColor={buttonBackgroundColor}
          textColor={buttonTextColor}
        />
      </div>
    </div>
  );
};

export default ButtonBlock;
