import clsx from "clsx";

const Spacer = ({
	size = "md",
	hideOnMobile = false,
	horizontalRule = false,
	borderColor = "#000000",
	className = "",
}) => {
	return (
		<div
			className={clsx("Spacer relative w-full", className, {
				block: !hideOnMobile,
				"hidden lg:block": hideOnMobile,
				"Spacer--size--none h-[1px]": size === "none",
				"Spacer--size--xs h-2.5 md:h-5 lg:h-5": size === "xs",
				"Spacer--size--sm h-5 md:h-7.5 lg:h-7.5": size === "sm",
				"Spacer--size--md h-7.5 sm:h-15 lg:h-15": size === "md",
				"Spacer--size--lg h-10 lg:h-22": size === "lg",
				"Spacer--size--xl h-15 lg:h-35": size === "xl",
			})}
		>
			{horizontalRule && (
				<div
					className={clsx(
						"Spacer__horizontalRule h-[1px] w-full max-w-screen-xl m-auto",
						"absolute inset-0"
					)}
					style={{ background: borderColor ? borderColor : "#000000" }}
				></div>
			)}
		</div>
	);
};

export default Spacer;
